import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import ArticlePreview from '../components/article'
import ActsiePreview from '../components/actsie'
import { FaHome, FaMailBulk } from 'react-icons/fa';
import navStyles from '../components/navigation.module.css';
import '../components/quote.css';
import Layout from '../layouts/layout'
import { graphql } from 'gatsby'
import Quote from '../components/quote'
import Logo from '../components/logo'


class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const geskrijven = get(this, 'props.data.allContentfulBlogPost.edges')
    const quotes = get(this, 'props.data.allContentfulAanhaaling.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const actsies = get(this, 'props.data.allContentfulActsie.edges')
    let actieQuote;
    let actiesLys;
    let geldigeActies = [];
    
    actsies.forEach(actsie => {
      let enddate = new Date(actsie.node.endDate);
      let publishDate = new Date(actsie.node.publishDate);
      let curDT = new Date();
      if(publishDate <= curDT && enddate >= curDT)
      {
        geldigeActies.push(actsie);
      }
      
    });
    const actsiesCount = geldigeActies.length;
    if (actsiesCount <= 0)
    {
      actieQuote =
      <blockquote>
      <p>
        Van tijd tot tijd worden er acties georganiseerd.
        Hier vindt u meer informatie.
      </p>
      <cite>Lea van der Riet-Luiten</cite>
      </blockquote>
      
      actiesLys =
      <ul className="article-list">            
      </ul>
    }
    else
    {      
      actiesLys =
      <ul className="article-list">
            {geldigeActies.map(({ node }) => {
              return (
                <li key={node.title}>
                  <ActsiePreview actsie={node} />
                </li>
              )
            })}
      </ul>
    }    
    return (      
      <Layout>
        <Helmet title={siteTitle} />
        <Logo author={author} />
        <div className="wrapper">
          <ul className="header-list">
            <li><div className={navStyles.iconimage}><FaHome  className={navStyles.icon}/></div></li>
            <h2><u>H</u>ome</h2>          
          </ul>
          <hr className="hr-repeat"></hr>
          
          <div className="quote">
            {actieQuote}
          </div>

          <div>
            {actiesLys}
          </div>
          <br/>
          
          <iframe width="100%" height="315" src="https://www.youtube.com/embed/mRdWu54YccU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>          
          
          <br/>
          
          <ul className="article-list">
            {quotes.map(({ node }) => {
              return (
                <li key={node.name}>
                  <Quote quote={node} />
                </li>
              )
            })}
          </ul>     
          
          <div className="quote">
            <blockquote>
            <p>
              Dit zijn zomaar wat citaten en briefjes van kinderen die bij ons op een kamp waren.
              Er is duidelijk een honger te zien naar het Woord van God.
              Wat is het een voorrecht om deze kinderen te vertellen van het Woord van God,
              hoe ze tot verlossing van hun zonden kunnen komen en God kunnen dienen!
            </p>
            <cite>Lea van der Riet-Luiten</cite>
          </blockquote>
          </div>
          <Link to="/lea/"><h3>Bekijk Profiel</h3></Link>
          <br/>
          <ul className="header-list">
          <li><div className={navStyles.iconimage}><FaMailBulk  className={navStyles.icon}/></div></li>
            <h2><u>N</u>ieuwsbrieven</h2>          
          </ul>
          <hr className="hr-repeat"></hr>
          <br/>          
          <ul className="article-list">
            {geskrijven.map(({ node }) => {
              return (
                <li key={node.slug}>
                  <ArticlePreview article={node} />
                </li>
              )
            })}
          </ul>          
        </div>
      </Layout>      
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
query HomeQuery {
  allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }, limit:3) {
    edges {
      node {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          fluid(maxWidth: 1180, maxHeight: 480, resizingBehavior: PAD) {
           ...GatsbyContentfulFluid
          }
        }
        downloadLink
        description {
          childMarkdownRemark {
            html
          }
        }
        author{image{
          fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
           ...GatsbyContentfulFluid
            }            
        },
          name
        }
      }
    }
  }
  allContentfulPerson(filter: { id: { eq: "e339d456-b3e8-5213-8d99-cf8ebb14a0a0" } }) {
    edges {
      node {
        name
        shortBio {
          shortBio
        }
        headline
        title
        heroImage: image {
          fluid(
            maxWidth: 1180
            maxHeight: 480
            resizingBehavior: PAD
            background: "rgb:000000"
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulAanhaaling(sort: { fields: [publikasieDatum], order: DESC }, limit:3) {
    edges {
      node {
        aanhaaling,
        naam,
        publikasieDatum,
        plaatje {
          fluid(
            maxWidth: 1180
            maxHeight: 480
            resizingBehavior: PAD            
          ) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
  allContentfulActsie(sort: { fields: [endDate], order: DESC }, limit:5){
    edges {
      node {
        title   
        publishDate
        endDate
        tags
        heroImage {
          fluid(maxWidth: 1180, maxHeight: 480, resizingBehavior: PAD) {
           ...GatsbyContentfulFluid
          }
        }        
        description {
          childMarkdownRemark {
            html
          }
        }
        author{image{
          fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
           ...GatsbyContentfulFluid
            }            
        },
          name
        }
      }
    }
  }  
}`
