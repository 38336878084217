import React from 'react'
import styles from './actsie.module.css'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Img from 'gatsby-image'

export default ({ actsie }) => (
  <div className={styles.link} onClick={() => window.open(actsie.heroImage.fluid.src)}>
    <Card >
      <CardHeader
        avatar={
          <Avatar alt={actsie.author.name} src={actsie.author.image.fluid.src} className={styles.avatar}></Avatar>
        }    
        title={actsie.title}
        subheader={actsie.author.name+ ": " + actsie.publishDate}
      />
      <CardContent>
      <p>{actsie.description.childMarkdownRemark.html.replace(/<\/p>/g,"").replace(/<p>/g,"")}</p>
      <p>(Klik om meer te lezen)</p>      
      </CardContent>  
      <Img className={styles.poster} alt="" fluid={actsie.heroImage.fluid} />
    </Card> 
  </div>
)
