import React from 'react'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Img from 'gatsby-image'

export default ({ quote }) => (
<Card>
  <CardHeader        
    title={quote.naam}    
  />
  <Img alt="" fluid={quote.plaatje.fluid} />
  <CardContent>
     <q>{quote.aanhaaling}</q>
  </CardContent> 
  </Card> 

)